.profile {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 82vh;
    margin-bottom: 90px;
    /* background-color: #f5f5f5; */
}

.profile-container {
    width: 80%;
    padding: 20px;
    /* background-color: #fff; */
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.profile-container h4 {
    font-size: 24px;
}
.profile-info{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.profile-field {
    width: 100%;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
}
/* Mobile Devices (up to 480px) */
@media (max-width: 480px) {
    .profile-container {
        width: 90%;
        padding: 15px;
    }

    .profile-container h4 {
        font-size: 20px;
    }

    .profile-field input {
        width: 100%;
        padding: 8px;
    }

    .profile-field button {
        width: 100%;
    }
}

/* Tablets (481px - 768px) */
@media (min-width: 481px) and (max-width: 768px) {
    .profile-container {
        width: 85%;
        padding: 20px;
    }

    .profile-container h4 {
        font-size: 22px;
    }

    .profile-field input {
        width: 80%;
    }

    .profile-field button {
        width: 80%;
    }
}

/* Laptops (769px - 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
    .profile-container {
        width: 600px;
        padding: 25px;
    }

    .profile-field input {
        width: 70%;
    }

    .profile-field button {
        width: 70%;
    }
}

/* Desktops (1025px and above) */
@media (min-width: 1025px) {
    .profile-container {
        width: 500px;
    }

    .profile-field input {
        width: 60%;
    }

    .profile-field button {
        width: auto;
    }
}
