.not-found-container {
    text-align: center;
    padding: 50px;
    font-family: Arial, sans-serif;
}

h1 {
    font-size: 100px;
    margin-bottom: 20px;
    color: #ff6347;
}

h2 {
    font-size: 36px;
    margin-bottom: 10px;
}

p {
    font-size: 18px;
    margin-bottom: 30px;
}

.back-home-link {
    text-decoration: none;
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.back-home-link:hover {
    background-color: #218838;
}
