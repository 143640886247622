.home {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    min-height: 75vh;
    padding: 20px;
    margin-bottom: 70px;
}

.text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #000;
    margin: 0 0 0 10px;
    padding: 0;
    width: 50%;
}

.text h1 {
    font-size: 28px;
    font-weight: 700;
    color: #000;
    margin: 0 0 10px 0;
}

.text h4 {
    font-size: 18px;
    font-weight: 500;
    color: #000;
    margin: 0;
}

.text p {
    font-size: 18px;
    font-weight: 400;
    color: #000;
    margin: 0 0 5px 0;
}

.image-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.image {
    max-width: 100%;
    height: auto;
}

/* Media Queries */

/* For tablets (768px and below) */
@media (max-width: 768px) {
    .home {
        flex-direction: column;
        min-height: auto;
        padding: 10px;
    }

    .text {
        width: 100%;
        margin: 0;
    }

    .image-section {
        width: 100%;
        margin-top: 20px;
    }

    .text h1 {
        font-size: 24px;
    }

    .text h4 {
        font-size: 16px;
    }

    .text p {
        font-size: 16px;
    }
}

/* For mobile phones (480px and below) */
@media (max-width: 480px) {
    .home {
        padding: 5px;
        min-height: 80vh;
    }

    .text h1 {
        font-size: 20px;
    }

    .text h4 {
        font-size: 14px;
    }

    .text p {
        font-size: 14px;
    }

    .image-section {
        margin-top: 10px;
    }
}
