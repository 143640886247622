img {
    width: 200px;
    height: 200px;
}

.about-page {
    width: 100%;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 90px;
}

.about-page h4 {
    margin-top: 20px;
    text-align: center;
    font-size: 25px;
}

.about-page p {
    margin-top: 30px;
    text-align: center;
    font-size: 18px;
}

.developers {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.developers h4 {
    margin-bottom: 20px;
    font-size: 24px;
    width: 100%;
}

.developer-card {
    width: 300px;
    margin: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    border-radius: 15px;
}

.developer-card img {
    width: 200px;
    height: 300px;
    margin: 20px auto;
    border-radius: 15px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.developer-card img:hover {
    transform: scale(1.4, 1.05);
}

.developer-card p {
    margin: 10px;
    font-size: 16px;
}

.developer-card .name {
    font-weight: 600;
}

.developer-card .role {
    font-weight: 500;
}

.developer-card .email {
    font-weight: 600;
    color: #3248a8;
    cursor: pointer;
}

@media (max-width: 480px) {
    .about-page {
        width: 100%;
    }
    .about-page p {
        font-size: 15px;
    }
    .developers {
        width: 95%;
    }
    .developer-card img {
        width: 150px;
        height: 200px;
    }
    
    .developer-card img:hover {
        transform: scale(1.2, 1.05);
    }

    .developer-card p {
        margin: 5px 10px;
    }
}