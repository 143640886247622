.social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.social-icons a {
    margin: 0 10px;
}

.social-icons a img {
    width: 30px;
    height: 30px;
}

.footer {
    width: 100%;
    background-color: #3248a8;
    color: #fff;
    text-align: center;
    position: fixed;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.footer p {
    margin: 0 ;
}

.footer a {
    color: #fff;
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}

.icon {
    margin: 5px;
}

@media (max-width: 480px) {
    .footer {
        width: 90%;
    }
}